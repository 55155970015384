<template>
  <div fp-request-reset-password>
    <ValidationObserver v-if="isLoad" tag="div" class="field-holder" ref="validator" v-slot="{ invalid }">
      <div class="content">
        <FpTitle :title="title" :back="false" />
        <p class="desc" v-if="desc" v-html="desc" />
        <FpReset :model-info="model" :structure="structure" />
      </div>
      <div class="bottom">
        <ProgressButton button-name="reset" :progress="isProgress" @click="submit" :disabled="invalid|| structure.error" />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FpReset from '@/views/components/pages/forgot-password/template/FpReset.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import FpTitle from '@/views/components/pages/forgot-password/FpTitle.vue';
import fpErrorHandler from '@/mixins/forgot-password/fpErrorHandler';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
export default {
  name: 'FpRequestResetPassword',
  mixins: [fpErrorHandler],
  components: { FpTitle, FpReset, ProgressButton },
  props: {
    structure: Specific,
  },
  data() {
    return {
      isProgress: false,
      isLoad: false,
      model: {
        VerificationToken: null,
        UserName: null,
        Email: null,
        Type: 'RESET_PASSWORD_ADMIN',
        Method: 'TOKEN',
        Password: null,
        RecaptchaAction: null,
        RecaptchaSiteKey: null,
        RecaptchaToken: null,
        Token: null,
        LoginMethod : null,
        VerificationCodeType : 'RESET_PASSWORD_ADMIN',
        SendVerificationType: 'EMAIL',
      }
    };
  },
  computed: {
    brandId: state('env', 'gpBrand'),
    site: state('env', 'site'),
    siteInfo: state('env', 'siteInfo'),
    title() {
      return 'forgotPassword.title.resetPassword';
    },
    desc() {
      return this.$t('forgotPassword.description.enterPassword');
    },
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
  },
  methods: {
    async loadCaptcha() {
      this.captcha = {};
      const action = 'RESET_PASSWORD';
      this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(action);
    },
    async submit() {
      await this.loadCaptcha();
      this.isProgress = true;

      const payload = {
        ...this.model,
        Email : null,
      }

      const result = await this.$services.ggpass.resetPassword(payload);

      if (result.error) {
        this.apiErrorHandler(result);
        let key = result.key;
        this.structure.error = this.error.error;
        this.structure.errorMsg = this.error.errorMsg;
        return;
      }

      this.structure.success = true;
      this.structure.hasButton = false;
      await this.routeName('FpComplete');

    },
    // async getVerification() {
    //   const result = await this.$services.ggpass.getVerification(this.model.Token, 'RESET_PASSWORD');
    //   if (result.error) {
    //     this.apiErrorHandler(result);
    //     this.errorMsg = this.error.errorMsg;
    //     return false;
    //   }
    //   const statusResult = this.statusHandler(result.status);
    //
    //   return statusResult;
    // },
    async sendRequest() {
      const result = await this.$services.ggpass.verifyCode(this.model);
      if (result.error) {
        await this.replaceErrorComplete(result);
        return;
      }

      if (result.accessToken) {
        this.$store.commit('user/setGpToken', result.accessToken);
        await sleep(60);
        this.isLoad = true;
      }

      const statusResult = this.statusHandler(result.status);
      if (statusResult.error) {
        this.success = false;
        this.$t(statusResult.key);
      } else {
        this.success = true;
      }
    },
    async replaceErrorComplete(result){
      this.success = false;
      this.structure.success = false;
      this.structure.hasButton = false;
      this.structure.completeReset = false;
      this.apiErrorHandler(result);
      this.structure.error = this.error.error;
      this.errorMsg = this.error.errorMsg;
      this.structure.errorMsg = this.error.errorMsg;

      await this.routeName('FpComplete');
      return true;
    },
  },
  async mounted() {
    const { verificationToken, userName } = this.$route.query;
    const VerificationToken = verificationToken || null;
    const UserName = userName || null;

    this.model = {
      ...this.model,
      Token: VerificationToken,
      UserName: UserName,
      Email : UserName,
      LoginMethod : "Email"
    };
    // const r = await this.getVerification();
    // if (!r) {
    //   return;
    // }
    await this.sendRequest();
  },
  beforeDestroy() {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-request-reset-password] {.flex(); .flex-dc(); .h(100%);
  .field-holder {flex: 1; .flex(); .flex-dc();
    .content {flex: 1;}
    .bottom {.w(100%); .mb(100);
      [progress-button] {.max-w(100%);}
    }
  }
}
</style>